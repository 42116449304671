import _ from 'lodash';
window._ = _;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// window.axios.defaults.withCredentials = true;

/**
 * @description: 这里检测响应头，如果token过期(返回401或419)时，重新登录
 * @Date: 2023-02-16 16:18:18
 */
window.axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401 || error.response?.status === 419) {
      if (JSON.parse(localStorage.getItem('loggedIn'))) {
        localStorage.setItem('loggedIn', false);
        localStorage.setItem('token', null);
        // location.assign('login')
      }
    }

    return Promise.reject(error);
  }
);
